import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62a201fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full grid grid-cols-2 p-4 2xl:p-6 gap-x-3 gap-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsCardListItemHeader = _resolveComponent("ClinicsCardListItemHeader")!
  const _component_SnapshotsChart = _resolveComponent("SnapshotsChart")!
  const _component_ClinicCardSnapshotsCount = _resolveComponent("ClinicCardSnapshotsCount")!
  const _component_ClinicCardWorkersCountChart = _resolveComponent("ClinicCardWorkersCountChart")!

  return (_openBlock(), _createElementBlock("li", {
    class: "shadow-custom hover:shadow-none transition duration-500 ease-in-out bg-white rounded-xl border border-gray-100 divide-y divide-gray-200 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activateClinic && _ctx.activateClinic(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ClinicsCardListItemHeader, {
        clinic: _ctx.clinic,
        class: "col-span-full"
      }, null, 8, ["clinic"]),
      (_ctx.totalPicturesCount)
        ? (_openBlock(), _createBlock(_component_SnapshotsChart, {
            key: 0,
            groupedSnapShots: _ctx.groupedSnapShots,
            class: "col-span-2"
          }, null, 8, ["groupedSnapShots"]))
        : _createCommentVNode("", true),
      (_ctx.totalPicturesCount)
        ? (_openBlock(), _createBlock(_component_ClinicCardSnapshotsCount, {
            key: 1,
            totalPicturesCount: _ctx.totalPicturesCount,
            class: "col-span-full md:col-span-1"
          }, null, 8, ["totalPicturesCount"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ClinicCardWorkersCountChart, {
        clinic: _ctx.clinic,
        class: _normalizeClass(_ctx.totalPicturesCount ? 'col-span-full md:col-span-1' : '')
      }, null, 8, ["clinic", "class"])
    ])
  ]))
}