import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-3 py-4 w-1/4 truncate whitespace-nowrap text-sm font-medium text-gray-900" }
const _hoisted_2 = { class: "px-3 py-4 w-1/4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_3 = { class: "px-3 py-4 w-1/4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_4 = { class: "h-6 w-20 rounded border border-gray-200 bg-gray-50 inline-flex justify-around" }
const _hoisted_5 = { class: "px-3 py-4 w-1/4 whitespace-nowrap text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    class: "cursor-pointer hover:bg-gray-100 transition-all transform duration-200 ease-in-out flex w-full",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activateClinic && _ctx.activateClinic(...args)))
  }, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.clinic.name), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.clinic.rapporteurName), 1),
    _createElementVNode("td", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.activeWorkersCount) + "/" + _toDisplayString(_ctx.clinic.usersCount), 1)
    ]),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.snapshotsCount), 1)
  ]))
}