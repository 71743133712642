
import {computed, defineComponent, onMounted, ref} from 'vue'

export default defineComponent({
  name: "SnapshotChartItem",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    maxValue: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const barValue = ref(0)
    onMounted(() => setTimeout(() => barValue.value = (props.value / props.maxValue) * 100, 500))
    const barProgress = computed(() => `${barValue.value}%`)
    return {barProgress}
  }
})
