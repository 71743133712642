<template>
  <div class="w-full h-60 bg-primary-light-blue bg-opacity-40 rounded-xl">
    <div class="text-sm font-bold tracking-wide uppercase text-blue-900 pt-4 pl-4">Töötajad</div>
    <svg viewBox="0 0 250 250"  :id="svgID" class="w-40 mx-auto">
      <g transform="translate(125,125)">
        <text :dx="countPosition" dy="15" fill="#374151" class="font-semibold text-5xl tracking-widest">
          {{ activeWorkersCount }}/{{ clinic.usersCount }}
        </text>
      </g>
    </svg>
    <div class="inline-flex items-center justify-around w-full">
      <div class="inline-flex items-center">
        <div class="w-4 h-4 bg-indigo-400 mr-2"></div>
        <div class="text-sm tracking-wide text-gray-700">aktiivsed</div>
      </div>
      <div class="inline-flex items-center">
        <div class="w-4 h-4 bg-yellow-300 mr-2"></div>
        <div class="text-sm tracking-wide text-gray-700">vaba</div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, watch} from 'vue'
import * as d3 from 'd3'
import {useWorkersStore} from "@/store/workers/WorkersStore";

export default defineComponent({
  name: "ClinicCardWorkersCountChart",
  props: {
    clinic: {
      type: Object,
      required: true
    }
  },
  setup(props) {

    const svgID = computed(() => `workers-count-chart-${props.clinic.uid}`)

    const workersStore = useWorkersStore();
    const workersState = workersStore.getState();
    const activeWorkers = computed(() =>
        workersState.workers.filter(
            (worker) => worker.active && worker.clinicUID === props.clinic.uid
        )
    );
    const activeWorkersCount = computed(() => activeWorkers.value.length);
    const unusedWorkersCount = computed(() => props.clinic.usersCount - activeWorkersCount.value)
    const workersLoaded = computed(() => workersState.loaded)

    const loadChart = () => {
      // input data
      const data = [
        {
          name: 'Kasutamata',
          id: "unused",
          value: unusedWorkersCount.value,
          color: '#FCD34D',
        },
        {
          name: 'Aktiivseid',
          id: "used",
          value: activeWorkersCount.value,
          color: '#818CF8',
        },

      ];

// retrieve the svg in which to plot the viz
      const svg = d3
          .select(`#${svgID.value}`);

// identify the dimensions of the viewBox to establish the svg canvas
      const viewBox = svg.attr('viewBox');
      const regexViewBox = /\d+ \d+ (\d+) (\d+)/;

      const [, viewBoxWidth, viewBoxHeight] = viewBox.match(regexViewBox).map(item => Number.parseInt(item, 10));

// with the margin convention include a group element translated within the svg canvas
      const margin = {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      };
// compute the width and height of the actual viz from the viewBox dimensions and considering the margins
// this to later work with width and height attributes directly through the width and height variables
      const width = viewBoxWidth - (margin.left + margin.right);
      const height = viewBoxHeight - (margin.top + margin.bottom);

// compute the radius as half the minor size between the width and height
      const radius = Math.min(width, height) / 2;
// initialize a variable to have the multiple elements share the same stroke-width property
      const strokeWidth = 10;

      const group = svg
          .append('g')
          .attr('transform', `translate(${margin.left} ${margin.top})`);


// DEFAULT CIRCLE
// circle used as a background for the colored donut chart
// add a group to center the circle in the canvas (this to rotate the circle from the center)
      const groupDefault = group
          .append('g')
          .attr('transform', `translate(${width / 2} ${height / 2})`);


// append the circle showing only the stroke
      groupDefault
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', radius)
          .attr('transform', 'rotate(-90)')
          .attr('fill', 'none')
          .attr('stroke', 'white')
          .attr('stroke-width', strokeWidth)
          .attr('stroke-linecap', 'round')
          // hide the stroke of the circle using the radius
          // this to compute the circumference of the shape
          .attr('stroke-dasharray', radius * 3.14 * 2)
          .attr('stroke-dashoffset', radius * 3.14 * 2);


// COLORED CIRCLES
// pie function to compute the arcs
      const pie = d3
          .pie()
          .sort(null)
          .padAngle(0.12)
          // use either the value or the percentage in the dataset
          .value(d => d.value);

// arc function to create the d attributes for the path elements
      const arc = d3
          .arc()
          // have the arc overlaid on top of the stroke of the circle
          .innerRadius(radius)
          .outerRadius(radius);

      /* for each data point include the following structure
      g             // wrapping all arcs
        g           // wrapping each arc
          arc       // actual shape
          line      // connecting line
          text      // text label
        g
          arc
          ...
      */
// wrapping group, horizontally centered
      const groupArcs = group
          .append('g')
          .attr('transform', `translate(${width / 2} ${height / 2})`);

      const groupsArcs = groupArcs
          .selectAll('g')
          .data(pie(data))
          .enter()
          .append('g');

// include the arcs specifying the stroke with the same width of the circle element
      groupsArcs
          .append('path')
          .style('visibility', 'hidden')
          .attr('d', arc)
          .attr('fill', 'none')
          .attr('stroke', d => d.data.color)
          .attr('stroke-width', (d) => strokeWidth + (d.data.id === 'used' ? 6 : -2) * 0.8)

          .attr('stroke-linecap', 'round')
          .attr('stroke-linejoin', 'round')
          // hide the segments by applying a stroke-dasharray/stroke-dashoffset equal to the circle circumference
          // ! the length of the element varies, and it considered afterwords
          // for certain the paths are less than the circumference of the entire circle
          .attr('stroke-dasharray', radius * 3.14 * 2)
          .attr('stroke-dashoffset', radius * 3.14 * 2);


      // TRANSITIONS
      // once the elements are set up
      // draw the stroke of the larger circle element

      groupDefault
          .select('circle')
          .transition()
          .ease(d3.easeExp)
          .delay(200)
          .duration(2000)
          .attr('stroke-dashoffset', '0')
          // once the transition is complete
          // draw the smaller strokes one after the other
          .on('end', () => {
            // immediately set the stroke-dasharray and stroke-dashoffset properties to match the length of the path elements
            // using vanilla JavaScript
            const paths = document.querySelectorAll('svg g g path');
            paths.forEach((path) => {
              path.style.visibility = 'visible'
              const length = path.getTotalLength();
              path.setAttribute('stroke-dasharray', length.toString());
              path.setAttribute('stroke-dashoffset', length.toString());
            });

            const duration = 1000;
            // transition the path elements to stroke-dashoffset 0
            d3
                .selectAll('svg g g path')
                .transition()
                .ease(d3.easeLinear)
                .delay((d, i) => i * duration)
                .duration(duration)
                .attr('stroke-dashoffset', 0);

            // transition the line elements elements to stroke-dashoffset 0
            d3
                .selectAll('svg g g line')
                .transition()
                .ease(d3.easeLinear)
                .delay((d, i) => i * duration + duration / 2.5)
                .duration(duration / 3)
                .attr('stroke-dashoffset', 0);

            // transition the text elements to opacity 1 and visibility visible
            d3
                .selectAll('svg g g text')
                .transition()
                .ease(d3.easeLinear)
                .delay((d, i) => i * duration + duration / 2)
                .duration(duration / 2)
                .style('opacity', 1)
                .style('visibility', 'visible');
          });
    }

    onMounted(() => {
      watch(
          () => workersLoaded.value,
          (value) => {
            if (value) loadChart()
          },
          {immediate: true}
      )
    })

    const countPosition = computed(() => {
      if (activeWorkersCount.value < 10 && props.clinic.usersCount < 10) return '-40'
      else if (activeWorkersCount.value < 10 || props.clinic.usersCount < 10) return '-50'
      return '-65'
    })


    return {svgID, activeWorkersCount, countPosition}
  }
})
</script>

<style scoped>

</style>
