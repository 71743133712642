import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative pb-8" }
const _hoisted_2 = {
  class: "absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-50",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "relative flex space-x-3 pt-1.5" }
const _hoisted_4 = { class: "min-w-0 flex-1 pl-2 flex xl:flex-nowrap xl:space-x-4" }
const _hoisted_5 = { class: "text-base font-medium text-gray-900" }
const _hoisted_6 = { class: "mt-1 text-sm font-normal text-gray-600" }
const _hoisted_7 = { class: "mt-2 px-1 text-sm whitespace-nowrap text-gray-500 inline-flex bg-gray-50 rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("span", _hoisted_2, null, 512), [
        [_vShow, !_ctx.last]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: _normalizeClass(["h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-gray-50", _ctx.bgColor])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent)))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.notification.clinicName), 1),
            _createElementVNode("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.notification.title) + " - ", 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.notification.message), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("time", null, _toDisplayString(_ctx.date), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}