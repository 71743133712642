
import {defineComponent, onMounted, ref} from 'vue'

export default defineComponent({
  name: "ClinicCardSnapshotsCount",
  props: {
    totalPicturesCount: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const startTime = ref<number | null>(null)
    const totalPicturesCountEl = ref<HTMLDivElement | null>(null)

    const step = (currentTime: number) => {
      if (!totalPicturesCountEl.value) return
      if (!startTime.value) startTime.value = currentTime
      const progress = Math.min((currentTime - startTime.value) / 500, 1);
      totalPicturesCountEl.value.innerHTML = Math.floor(progress * props.totalPicturesCount).toString();
      if (progress < 1) window.requestAnimationFrame(step);
      else window.cancelAnimationFrame(window.requestAnimationFrame(step));

    }


    onMounted(() => window.requestAnimationFrame(step))

    return {totalPicturesCountEl}
  }
})
