
import {defineComponent, PropType} from 'vue'
import {ClinicDto} from "@/shared/types";

export default defineComponent({
  name: "ClinicsCardListItemHeader",
  props: {
    clinic: {
      type: Object as PropType<ClinicDto>,
      required: true,
    },
  },
  setup() {
    return {}
  }
})
