
import { computed, defineComponent, PropType } from "vue";
import { NotificationDTO, NotificationType } from "@/shared/types";
import UsersIconSmall from "@/components/utils/icons/UsersIconSmall.vue";
import UserGroupIconSmall from "@/components/utils/icons/UserGroupIconSmall.vue";
import ExclamationIconSmall from "@/components/utils/icons/ExclamationIconSmall.vue";
import CubeIconSmall from "@/components/utils/icons/CubeIconSmall.vue";
import BeakerIconSmall from "@/components/utils/icons/BeakerIconSmall.vue";
import InformationCircleIconSmall from "@/components/utils/icons/InformationCircleIconSmall.vue";

export default defineComponent({
  name: "DashboardHomeNotificationsFeedListItem",
  components: {},
  props: {
    notification: {
      type: Object as PropType<NotificationDTO>,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const date = computed(() =>
      new Date(props.notification.timestamp)
        .toLocaleDateString("en-GB")
        .split("/")
        .join(".")
    );

    const bgColors: Record<NotificationType, `bg-${string}-${number}`> = {
      [NotificationType.ACTIVE_USER_COUNT_FULL]: "bg-red-400",
      [NotificationType.NEW_USER_COUNT]: "bg-green-400",
      [NotificationType.NEW_CABINET]: "bg-blue-400",
      [NotificationType.NEW_X_RAY_EQUIPMENT]: "bg-purple-400",
      [NotificationType.MAINTENANCE_DATE_APPROACHING]: "bg-yellow-200",
      [NotificationType.MEASUREMENT_DATE_APPROACHING]: "bg-yellow-200",
    };

    const bgColor = computed(() => bgColors[props.notification.type]);

    const iconComponents: Record<NotificationType, unknown> = {
      [NotificationType.ACTIVE_USER_COUNT_FULL]: ExclamationIconSmall,
      [NotificationType.NEW_USER_COUNT]: UserGroupIconSmall,
      [NotificationType.NEW_CABINET]: BeakerIconSmall,
      [NotificationType.NEW_X_RAY_EQUIPMENT]: CubeIconSmall,
      [NotificationType.MAINTENANCE_DATE_APPROACHING]:
        InformationCircleIconSmall,
      [NotificationType.MEASUREMENT_DATE_APPROACHING]:
        InformationCircleIconSmall,
    };

    const iconComponent = computed(
      () => iconComponents[props.notification.type]
    );

    return { date, bgColor, iconComponent };
  },
});
