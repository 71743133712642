
import { computed, defineComponent, onMounted } from "vue";
import { useClinicsStore } from "@/store/clinics/ClinicsStore";
import DashboardHomeClinicsCardListItem from "@/components/dashboard/clinicscardlist/clinicscardlist/cardlistitem/ClinicsCardListItem.vue";
import { useSnapshotsStore } from "@/store/snapshots/SnapshotsStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";

export default defineComponent({
  name: "DashboardHomeClinicsCardList",
  components: { DashboardHomeClinicsCardListItem },
  setup() {
    const clinicsStore = useClinicsStore();
    const clinicsState = clinicsStore.getState();
    const workersStore = useWorkersStore();
    const snapshotsStore = useSnapshotsStore();
    onMounted(
      async () =>
        await Promise.all([
          clinicsStore.setClinics(false),
          workersStore.setWorkers(false),
          snapshotsStore.setSnapshots(false, false),
        ])
    );
    const clinics = computed(() => clinicsState.clinics);
    return { clinics };
  },
});
