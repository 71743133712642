
import {computed, defineComponent, PropType} from "vue";
import {ClinicDto, SnapshotPicture} from "@/shared/types";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useRouter} from "vue-router";
import {RouteName} from "@/router/types";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import ClinicCardWorkersCountChart from "@/components/dashboard/clinicscardlist/ClinicCardWorkersCountChart.vue";
import SnapshotsChart from "@/components/dashboard/clinicscardlist/snapshotschart/SnapshotsChart.vue";
import ClinicCardSnapshotsCount from "@/components/dashboard/clinicscardlist/ClinicCardSnapshotsCount.vue";
import ClinicsCardListItemHeader
  from "@/components/dashboard/clinicscardlist/clinicscardlist/cardlistitem/ClinicsCardListItemHeader.vue";

export default defineComponent({
  name: "DashboardHomeClinicsCardListItem",
  components: {ClinicsCardListItemHeader, ClinicCardSnapshotsCount, SnapshotsChart, ClinicCardWorkersCountChart},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    clinic: {
      type: Object as PropType<ClinicDto>,
      required: true,
    },
  },
  setup(props) {
    const clinicsStore = useClinicsStore();
    const snapshotsStore = useSnapshotsStore();
    const snapshotsState = snapshotsStore.getState();

    const snapshots = computed<SnapshotPicture[]>(() => {
      return snapshotsState.snapshots
          .filter((s) => props.clinic.cabinetsUID.includes(s.cabinetUID))
          .flatMap((s) => s.pictures);
    });


    const groupedSnapShots = computed(() =>
        Object.entries(
            snapshots.value.reduce((acc, curr) => {
              const labelSplit = curr.label.split('-')
              const label = labelSplit[labelSplit.length - 1].trim()
              if (!acc[label]) acc[label] = 0;
              acc[label] += curr.value;
              return acc;
            }, {} as Record<string, number>)
        ).sort((a, b) => b[1] - a[1])
    );

    const totalPicturesCount = computed(() =>
        groupedSnapShots.value.reduce((acc, curr) => acc + curr[1], 0)
    );

    const router = useRouter();
    const activateClinic = () => {
      clinicsStore.setActiveClinicUID(props.clinic.uid);
      router.push({name: RouteName.CLINICS_HOME});
    };


    return {
      activateClinic,
      groupedSnapShots,
      totalPicturesCount,
    };
  },
});
