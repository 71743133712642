
import { computed, defineComponent, PropType } from "vue";
import { ClinicDto } from "@/shared/types";

import { useWorkersStore } from "@/store/workers/WorkersStore";
import { useSnapshotsStore } from "@/store/snapshots/SnapshotsStore";
import { RouteName } from "@/router/types";
import { useClinicsStore } from "@/store/clinics/ClinicsStore";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "DashboardHomeClinicsListItem",
  components: {},
  props: {
    clinic: {
      type: Object as PropType<ClinicDto>,
      required: true,
    },
  },
  setup(props) {
    const workersStore = useWorkersStore();
    const workersState = workersStore.getState();

    const snapshotsStore = useSnapshotsStore();
    const snapshotsState = snapshotsStore.getState();

    const clinicsStore = useClinicsStore();

    const router = useRouter();

    const activeWorkers = computed(() =>
      workersState.workers.filter(
        (worker) => worker.active && worker.clinicUID === props.clinic.uid
      )
    );
    const activeWorkersCount = computed(() => activeWorkers.value.length);
    const snapshotsCount = computed(() =>
      snapshotsState.snapshots.reduce((acc, curr) => {
        acc += props.clinic.cabinetsUID.includes(curr.cabinetUID)
          ? curr.pictures.reduce((pacc, p) => (pacc += p.value), 0)
          : 0;
        return acc;
      }, 0)
    );

    const activateClinic = () => {
      clinicsStore.setActiveClinicUID(props.clinic.uid);
      router.push({ name: RouteName.CLINICS_HOME });
    };
    return { activeWorkersCount, snapshotsCount, activateClinic };
  },
});
