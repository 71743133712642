
import {RouteName} from "@/router/types";
import {computed, defineComponent} from "vue";
import DashboardHomeClinicsCardList from "@/components/dashboard/clinicscardlist/clinicscardlist/ClinicsCardList.vue";
import {useUserStore} from "@/store/user/UserStore";
import DashboardHomeNotificationsFeedList
  from "@/components/dashboard/notificationsfeed/DashboardHomeNotificationsFeedList.vue";
import DashboardHomeClinicsList from "@/components/dashboard/clinicslist/DashboardHomeClinicsList.vue";

export default defineComponent({
  name: RouteName.DASHBOARD_HOME,
  components: {
    DashboardHomeClinicsList,
    DashboardHomeNotificationsFeedList,
    DashboardHomeClinicsCardList,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const isAdmin = computed(() => userState.admin);

    return {isAdmin};
  },
});
