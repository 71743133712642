import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col max-h-screen" }
const _hoisted_2 = { class: "pl-6 flex w-full flex-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "w-full lg:w-3/5 xl:w-1/2"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full lg:w-3/5 xl:w-1/2"
}
const _hoisted_5 = { class: "w-full lg:w-2/5 xl:w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHomeClinicsCardList = _resolveComponent("DashboardHomeClinicsCardList")!
  const _component_DashboardHomeClinicsList = _resolveComponent("DashboardHomeClinicsList")!
  const _component_DashboardHomeNotificationsFeedList = _resolveComponent("DashboardHomeNotificationsFeedList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isAdmin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_DashboardHomeClinicsCardList)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_DashboardHomeClinicsList)
            ])),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_DashboardHomeNotificationsFeedList)
        ])
      ])
    ])
  ]))
}