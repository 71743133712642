import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-6 pr-2 border-b border-gray-200 rounded-xl grid grid-cols-1 gap-y-8 max-h-90 overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHomeClinicsCardListItem = _resolveComponent("DashboardHomeClinicsCardListItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clinics, (clinic) => {
      return (_openBlock(), _createBlock(_component_DashboardHomeClinicsCardListItem, {
        key: clinic.uid,
        clinic: clinic
      }, null, 8, ["clinic"]))
    }), 128))
  ]))
}