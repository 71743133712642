
import {computed, defineComponent, PropType} from "vue";
import SnapshotChartItem from "@/components/dashboard/clinicscardlist/snapshotschart/SnapshotChartItem.vue";

export default defineComponent({
  name: "SnapshotsChart",
  components: {SnapshotChartItem},
  props: {
    groupedSnapShots: {
      type: Array as PropType<[string, number][]>,
      required: true,
    },
  },
  setup(props) {
    const maxValue = computed(() =>
        Math.max(...props.groupedSnapShots.map((el) => el[1]))
    );
    return {maxValue};
  },
});
