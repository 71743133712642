
import {computed, defineComponent, onMounted} from 'vue'
import {useNotificationsStore} from "@/store/notifications/NotificationsStore";
import DashboardHomeNotificationsFeedListItem
  from "@/components/dashboard/notificationsfeed/DashboardHomeNotificationsFeedListItem.vue";

export default defineComponent({
  name: "DashboardHomeNotificationsFeedList",
  components: {DashboardHomeNotificationsFeedListItem},
  setup() {
    const notificationsStore = useNotificationsStore()
    const notificationsState = notificationsStore.getState()
    const notifications = computed(() => notificationsState.notifications)
    onMounted(async () => await Promise.all([notificationsStore.setNotifications(true)]))
    return {notifications}
  }
})
